import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  console.log(data)
  const { frontmatter, html } = markdownRemark
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{frontmatter.title} | GifJif</title>
        <meta
          name="description"
          content="GifJif is the best Gif app in the world"
        />
      </Helmet>
      <div className="border-t-8 border-primary-base flex mb-8">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 ">
          <h1 className="mt-8 mb-4 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
            {frontmatter.title}
          </h1>
          <h2 className="italic text-gray-500">{frontmatter.date}</h2>
          <hr />
          <div className="mt-4" dangerouslySetInnerHTML={{ __html: html }} />
        </div>
      </div>
    </Layout>
  )
}
export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
      }
    }
  }
`
